import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../container.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledAHteaser, StyledTextSection, AHBigSign } from "./StyleCsAutohop"
import AHlogoBanner from "./AHlogoBanner"

const AHteaser = props => {
  const data = useStaticQuery(graphql`
    query AHteaser {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            ktText
            ktStreetBanner1 {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            ktStreetBanner2 {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            ktLandingPageImage1 {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            ktLandingPageImage2 {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            ktCoutdownImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            ktSocialMedia {
              image {
                title
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      avifOptions: { lossless: true, quality: 100 }
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  const SocialMediaImages = ACF.ktSocialMedia.map((item, index) => {
    return (
      <div className="immageWrapper" index={index}>
        <GatsbyImage
          image={getImage(item.image.localFile)}
          alt={item.image.altText || "Social media"}
          title={item.image.title || "Social media"}
        />
      </div>
    )
  })

  return (
    <StyledAHteaser>
      <div className="leftColumn">
        <div className="backgroundSign">
          kamp<br></br>ania<br></br>teaswerowa
        </div>
        <StyledTextSection leftWidth="100%" color="#fff">
          <div className="left">
            <div className="name">kampania_teaserowa</div>
            <div className="desc">{ACF.ktText}</div>
          </div>
        </StyledTextSection>
      </div>
      <div className="rightColumn">
        <GatsbyImage
          image={getImage(ACF.ktStreetBanner1.localFile)}
          alt={ACF.ktStreetBanner1.altText || "AH teaser"}
          title={ACF.ktStreetBanner1.title || "AH teaser"}
        />
      </div>
      <div className="imageFull">
        <GatsbyImage
          image={getImage(ACF.ktStreetBanner2.localFile)}
          alt={ACF.ktStreetBanner2.altText || "autohop teaser"}
          title={ACF.ktStreetBanner2.title || "autohop teaser"}
        />
      </div>
      <div className="teaserImageBanner">
        <GatsbyImage
          image={getImage(ACF.ktLandingPageImage1.localFile)}
          alt={ACF.ktLandingPageImage1.altText || "autohop teaser"}
          title={ACF.ktLandingPageImage1.title || "autohop teaser"}
        />
        <div className="teaserBanner">
          <StyledTextSection leftWidth="100%" descWidth="60%">
            <div className="left">
              <div className="name">kampania_teaserowa</div>
              <div className="desc">
                Landing page z zegarem odliczającym czas do premiery usługi
              </div>
            </div>
          </StyledTextSection>
        </div>
        <div className="teaserScreenImage">
          <GatsbyImage
            image={getImage(ACF.ktLandingPageImage2.localFile)}
            alt={ACF.ktLandingPageImage2.altText || "autohop teaser"}
            title={ACF.ktLandingPageImage2.title || "autohop teaser"}
          />
        </div>
      </div>
      <Container>
        <StyledTextSection leftWidth="100%" descWidth="60%">
          <div className="left">
            <div className="name">kampania_teaserowa</div>
            <div className="desc">Komunikacja w mediach społecznościowych</div>
          </div>
        </StyledTextSection>
        <div className="socialMediaWrapper">{SocialMediaImages}</div>
        <div className="imageAlign">
          <GatsbyImage
            image={getImage(ACF.ktCoutdownImage.localFile)}
            alt={ACF.ktCoutdownImage.altText || "autohop social media"}
            title={ACF.ktCoutdownImage.title || "autohop social media"}
          />
        </div>
      </Container>
    </StyledAHteaser>
  )
}
export default AHteaser
