import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"
import Container from "../../container.js"

import { StyledAHAims, StyledTextSection } from "./StyleCsAutohop"

const AHaims = props => {
  const data = useStaticQuery(graphql`
    query AhAims {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            celeKomunikacjiList
            strategiaText
            celeKomunikacjiObraz {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  return (
    <div>
      <StyledAHAims>
        <div className="left">
          <GatsbyImage
            image={getImage(ACF.celeKomunikacjiObraz.localFile)}
            alt={ACF.celeKomunikacjiObraz.altText}
            title={ACF.celeKomunikacjiObraz.title}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        <div className="right">
          <div className="aim__bar">Cele_komunikacyjne</div>
          <div
            className="aim__list"
            dangerouslySetInnerHTML={{ __html: ACF.celeKomunikacjiList }}
          />
        </div>
      </StyledAHAims>
      <Container>
        <StyledTextSection>
          <div className="left">
            <div className="name">Strategia</div>
            <div className="desc"></div>
          </div>
          <div className="right">{ACF.strategiaText}</div>
        </StyledTextSection>
      </Container>
    </div>
  )
}
export default AHaims
