import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledAHLogoBanner, StyledTextSection } from "./StyleCsAutohop"

const AHlogoBanner = props => {
  return (
    <StyledAHLogoBanner
      background={props.background}
      textColor={props.textColor}
      numberColor={props.numberColor}
    >
      <div className="left">
        <div className="name">key_visual</div>
        <div className="desc">{props.text}</div>
      </div>
      <div className="right">
        <div className="logo">
          <GatsbyImage image={getImage(props.logo)} alt={props.logo.altText || "AHlogo"}  title={props.logo.title  || "AHlogo"} />
        </div>
        <div className="number">{props.number}</div>
      </div>
    </StyledAHLogoBanner>
  )
}
export default AHlogoBanner
