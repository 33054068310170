import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../container.js"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  StyledAHcampain,
  StyledTextSection,
  TwoColumns,
  StyledPrice,
  StyledVideosSection,
  AHBigSign,
} from "./StyleCsAutohop"
// import LightboxGallery from "../../molecules/LightboxGallery"


const AHcampain = props => {
  const data = useStaticQuery(graphql`
    query AHcampain {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            aeText
            kcBannerText

            aeVideos {
              videoLink
            }
            aeImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            ksText
            ksImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            kcBannerImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                  fixed(width: 1970) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
            kcBannerImage2 {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }

  return (
    <StyledAHcampain>
      <div className="fullWidthImageBanner">
        <div className="fullWidthImageBanner__content">
          <Container>
            <div className="fullWidthImageBanner__title">
              Taniej i wygodniej nie pojedziesz
            </div>
            <StyledTextSection leftWidth="40%" descWidth="60%" color="#fff">
              <div className="left">
                <div className="name">kampania_cenowa</div>
              </div>
              <div className="right">{ACF.kcBannerText}</div>
            </StyledTextSection>
          </Container>
        </div>
        <div className="fullWidthImageBanner__image">
          <GatsbyImage
            image={getImage(ACF.kcBannerImage.localFile)}
            alt={ACF.kcBannerImage.altText || "bannerImage"}
            title={ACF.kcBannerImage.title || "bannerImage"}
          />
        </div>
      </div>
      <TwoColumns
        className="CampainTwoColumns"
        coulmnWidth="50%"
        coulmnHeight="110rem"
        rightCoulmnBackground="#f17e00"
      >
        <div className="column leftColumn">
          <StyledPrice className="priceBox" priceColor="#161615">
            4.95
          </StyledPrice>
        </div>
        <div className="column rightColumn">
          <StyledPrice className="priceBox">
            <div className="icon">
              <div className="arrow"></div>
            </div>
            2.99
          </StyledPrice>
        </div>
        <figure className="bannerImage2">
          <GatsbyImage
            className="bannerImage2"
            image={getImage(ACF.kcBannerImage2.localFile)}
            alt={ACF.kcBannerImage2.altText || "bannerImage"}
            title={ACF.kcBannerImage2.title || "bannerImage"}
          />
        </figure>
      </TwoColumns>
      <Container>
        <StyledTextSection
          className="laptopImage"
          leftWidth="40%"
          descWidth="60%"
        >
          <div className="left">
            <div className="name">akcja_edukacyjna</div>
            <div className="desc">{ACF.aeText}</div>
          </div>
          <div className="right">
            <GatsbyImage
              className="bannerImage2"
              image={getImage(ACF.aeImage.localFile)}
              alt={ACF.aeImage.altText || "BielusiakLaptop"}
              title={ACF.aeImage.title || "BielusiakLaptop"}
            />
          </div>
        </StyledTextSection>
      </Container>


{/*       <StyledVideosSection>

        {ACF.aeVideos.map((item, index) => (
          <a
            key={index}
            className="StyledVideosSection__image"
            onClick={() => openLightboxOnSlide(index+1)}
          >
            <img src={"https://autohop.pl/wp-content/uploads/2019/09/gliwicka-jazda04-768x438.jpg"} />

          </a>
        ))} */}

{/*         <FsLightbox
          toggler={lightboxController.toggler}
          sources={ ACF.aeVideos.map((item, index) => item.videoLink) }
          slide={lightboxController.slide}
        /> */}
{/*       </StyledVideosSection> */}


      <AHBigSign fontSize="29.5rem" display="flex">
        Kampania
      </AHBigSign>
      <Container>
        <StyledTextSection leftWidth="40%" descWidth="60%">
          <div className="left">
            <div className="name">kampania_społeczna</div>
            <div className="desc">{ACF.ksText}</div>
          </div>
          <div className="right">
            <GatsbyImage
              className="bannerImage2"
              image={getImage(ACF.ksImage.localFile)}
              alt={ACF.ksImage.altText || "BielusiakLaptop"}
              title={ACF.ksImage.title || "BielusiakLaptop"}
            />
          </div>
        </StyledTextSection>
      </Container>

      {/* <StyledVideosSection>
        {ACF.aeVideos.map((item, index) => item.videoLink)}
      </StyledVideosSection> */}

    </StyledAHcampain>
  )
}
export default AHcampain
