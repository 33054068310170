import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"
import Container from "../../container.js"

import {
  StyledAHAction,
  StyledAHActionItems,
  AHBigSign,
} from "./StyleCsAutohop"

const AHactions = props => {
  const data = useStaticQuery(graphql`
    query AHactions {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            dzialania {
              text
              title
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  const ActionsBlocks = ACF.dzialania.map((item, index) => {
    return (
      <div
        className={"actions__item actions__item--" + (index + 1)}
        key={index}
      >
        <div className="ai__number">{"0" + (index + 1)}</div>
        <div className="ai__title">{item.title}</div>
        <div className="ai__text">{item.text}</div>
      </div>
    )
  })
  return (
    <StyledAHAction>
      <AHBigSign>Działania</AHBigSign>

      <Container>
        <StyledAHActionItems>
          <div className="actions__items">{ActionsBlocks}</div>
        </StyledAHActionItems>
      </Container>
    </StyledAHAction>
  )
}
export default AHactions
