import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "../../container.js"

import { StyledAHLogoes, StyledTextSection } from "./StyleCsAutohop"
import AHlogoBanner from "./AHlogoBanner"

const AHlogoes = props => {
  const data = useStaticQuery(graphql`
    query AHlogoes {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            keyVisualBannerText
            autohopLogo {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            autohopLogoOrange {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  return (
    <StyledAHLogoes>
      <AHlogoBanner
        text={ACF.keyVisualBannerText}
        number="01"
        logo={ACF.autohopLogo.localFile}
        background="#F27C00"
        textColor="#fff"
        numberColor="#F3891A"
      />
      <AHlogoBanner
        logo={ACF.autohopLogoOrange.localFile}
        textColor="#161615"
      />
      <Container>
        <StyledTextSection>
          <div className="left">
            <div className="name">key_visual</div>
            <div className="desc">Kolorystyka marki</div>
          </div>
          <div className="right"></div>
        </StyledTextSection>
      </Container>
    </StyledAHLogoes>
  )
}
export default AHlogoes
