import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../container.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  StyledAHstart,
  StyledTextSection,
  AHBigSign,
  TwoColumns,
} from "./StyleCsAutohop"
import AHlogoBanner from "./AHlogoBanner"

const AHstart = props => {
  const data = useStaticQuery(graphql`
    query AHstart {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            smStreetImages {
              image {
                title
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      avifOptions: { lossless: true, quality: 100 }
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            smCarImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            smLogoImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            smAppImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            smAppImage2 {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            smAppImage3 {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            smStreetImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  const ReapeterImages = ACF.smStreetImages.map((item, index) => {
    return (
      <div className="immageWrapper" index={index}>
        <GatsbyImage image={getImage(item.image.localFile)} alt={item.image.altText || "AHstart"}  title={item.image.title  || "AHstart"} />
      </div>
    )
  })

  return (
    <StyledAHstart>
      <AHBigSign fontSize="24.5rem" display="flex">
        Start_marki
      </AHBigSign>
      <Container>
        <div className="imageFull">
          <GatsbyImage
            image={getImage(ACF.smCarImage.localFile)}
            alt={ACF.smCarImage.altText || "AHCarImage"}
            title={ACF.smCarImage.title || "AHCarImage"}
          />
        </div>

        <TwoColumns>
          <div className="leftColumn">
            <StyledTextSection leftWidth="100%" descWidth="60%">
              <div className="left">
                <div className="name">start_marki</div>
                <div className="desc">
                  Na pierwsze dni działania AutoHOP-a zaplanowaliśmy mocne
                  uderzenie. Uaktywniliśmy wszystkie liczące się lokalne media,
                  zorganizowaliśmy akcję ambientową na ulicach miasta, do tego
                  dorzuciliśmy przemyślany outdoor i rzecz jasna poprowadziliśmy
                  pełną komunikację w sieci.
                </div>
              </div>
            </StyledTextSection>
            <div className="ReapeterImages">{ReapeterImages}</div>
          </div>
          <div className="rightCoulmn">
            <GatsbyImage
              image={getImage(ACF.smLogoImage.localFile)}
              alt={ACF.smLogoImage.altText || "StartMarki autohop"}
              title={ACF.smLogoImage.title || "StartMarki autohop"}
            />
          </div>
        </TwoColumns>
      </Container>
      <Container>
        <StyledTextSection leftWidth="100%" descWidth="60%">
          <div className="left">
            <div className="name">start_marki</div>
            <div className="desc">Start aplikacji i oficjalnej strony WWW</div>
          </div>
        </StyledTextSection>
      </Container>
      <Container>
        <TwoColumns wrapperPadding="7rem 0">
          <div className="leftColumn">
            <GatsbyImage
              image={getImage(ACF.smAppImage.localFile)}
              alt={ACF.smAppImage.altText}
              title={ACF.smAppImage.title}
            />
          </div>
          <div className="rightCoulmn">
            <div className="phoneImage">
              <GatsbyImage
                image={getImage(ACF.smAppImage2.localFile)}
                alt={ACF.smAppImage2.altText || "Telefon mockup"}
                title={ACF.smAppImage2.title || "Telefon mockup"}
              />
            </div>
          </div>
        </TwoColumns>
      </Container>
      <Container>
        <StyledTextSection leftWidth="100%" descWidth="60%">
          <div className="left">
            <div className="name">start_marki</div>
            <div className="desc">
              Strona WWW zaprojektowana dla wszystkich urządzeń - mobilnych i
              wielkoekranowych
            </div>
          </div>
          <div className="right">
            <div className="tabletImage">
              <GatsbyImage
                image={getImage(ACF.smAppImage3.localFile)}
                alt={ACF.smAppImage3.altText || "Tablet mockup"}
                title={ACF.smAppImage3.title || "Tablet mockup"}
              />
            </div>
          </div>
        </StyledTextSection>
        <StyledTextSection leftWidth="100%" descWidth="60%">
          <div className="left">
            <div className="name">start_marki</div>
            <div className="desc">Kampania outdoor</div>
          </div>
          <div className="right">
            <div className="tabletImage">
              <GatsbyImage
                image={getImage(ACF.smStreetImage.localFile)}
                alt={ACF.smStreetImage.altText || "Tablet mockup"}
                title={ACF.smStreetImage.title || "Tablet mockup"}
              />
            </div>
          </div>
        </StyledTextSection>
      </Container>
    </StyledAHstart>
  )
}
export default AHstart
