import React from "react"

import type { HeadFC, PageProps } from "gatsby"
import { SEO } from "../../components/seo"
import Layout from "../../components/layout"
import AHbanner from "../../components/organisms/CsAutoHop/AHbanner"
import AHaims from "../../components/organisms/CsAutoHop/AHaims"
import AHactions from "../../components/organisms/CsAutoHop/AHactions"
import AHlogoes from "../../components/organisms/CsAutoHop/AHlogoes"
import AHcolors from "../../components/organisms/CsAutoHop/AHcolors"
import AHteaser from "../../components/organisms/CsAutoHop/AHteaser"
import AHstart from "../../components/organisms/CsAutoHop/AHstart"
import AHcampain from "../../components/organisms/CsAutoHop/AHtcampain"
import NextItem from "../../components/molecules/NextItem"
import PreviousItem from "../../components/molecules/PreviousItem"
import NextPreview from "../../components/atoms/NextPreview"

export default function CaseStudy(props) {
  const SEO = props.pageContext.seo

  const ACF = props.data

  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri}>
      <div style={{ overflowX: 'hidden'}}>
      <AHbanner />
      <AHaims />
      <AHactions />
      <AHlogoes />
      <AHcolors />
      <AHteaser />
      <AHstart />
      <AHcampain />
      <NextPreview>
        {props.pageContext.previous && (
          <PreviousItem
            prevLink={props.pageContext.previous.uri}
            prevName="Poprzednie Case Study"
          />
        )}
        {props.pageContext.next && (
          <NextItem
            nextLink={props.pageContext.next.uri}
            nextName="Następne Case Study"
          />
        )}
      </NextPreview>
      </div>
    </Layout>
  )
}



export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
