import styled from "styled-components"

export const StyledAHbanner = styled.section`
  background: #f27c00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40rem;
  margin-bottom: 5rem;

  h1 {
    font-size: 2.5rem;
    color: #fff;
    font-weight: 600;
    font-family: ${props => props.theme.fonts.titillium};
    text-transform: uppercase;

    @media ${props => props.theme.sizes.laptop} {
      font-size: 10rem;
    }
  }
  .gatsby-image-wrapper {
    max-width: 80%;
  }
`
export const TwoColumns = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;

  @media ${props => props.theme.sizes.laptopL} {
    flex-direction: row;
    padding: ${props => props.wrapperPadding || "0"};
  }

  .column {
    background: ${props => props.coulmnBackground || "#ff"};
    width: ${props => props.coulmnWidth || "unset"};

    @media ${props => props.theme.sizes.desktop} {
      height: ${props => props.coulmnHeight || "unset"};
    }
  }

  .leftColumn {
    padding: ${props => props.leftPadding || "0"};
    background: ${props => props.leftCoulmnBackground || "#ff"};
    position: relative;
  }
  .rightColumn {
    padding: ${props => props.rightPadding || "0"};
    background: ${props => props.rightCoulmnBackground || "#ff"};
    position: relative;
  }
`

export const StyledTextSection = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.fonts.titillium};

  @media ${props => props.theme.sizes.laptopL} {
    flex-direction: row;
    padding: 2rem;
  }

  .left {
    @media ${props => props.theme.sizes.laptopL} {
      width: ${props => props.leftWidth || "30%"};
    }
  }

  .right {
    color: ${props => props.color || "black"};
    margin-top: 2rem;

    @media ${props => props.theme.sizes.laptopL} {
      width: 70%;
      padding: 0.5rem;
      margin-top: 0;
    }
  }
  .name {
    font-size: 2rem;
    font-weight: 700;
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    color: ${props => props.color || "black"};

    &:after {
      content: "";
      width: 3rem;
      background: ${props => props.color || "black"};
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .desc {
    color: ${props => props.color || "black"};

    @media ${props => props.theme.sizes.laptop} {
      max-width: ${props => props.descWidth || "60%"};
    }
  }
  .slogan {
    font-family: ${props => props.theme.fonts.titillium};
    color: #f27c00;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2.75rem;

    @media ${props => props.theme.sizes.laptop} {
      font-size: 7.75rem;
    }
  }
`

export const StyledAHAims = styled.section`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  font-family: ${props => props.theme.fonts.titillium};

  @media ${props => props.theme.sizes.laptop} {
    flex-direction: row;
    padding: 5rem 0;
  }

  .left {
    z-index: 1;

    @media ${props => props.theme.sizes.laptop} {
      width: 45%;
    }

    .gatsby-image-wrapper {
      max-width: 100%;
    }
  }
  .right {
    z-index: 10;

    @media ${props => props.theme.sizes.laptop} {
      padding-right: 20%;
    }

    .aim {
      &__bar {
        background-color: #161615;
        color: #fff;
        z-index: 100;
        height: 12.5rem;
        display: flex;
        align-items: center;
        font-size: 1.7rem;
        font-weight: 700;
        justify-content: center;

        @media ${props => props.theme.sizes.laptop} {
          padding: 0 28%;
          font-size: 2.2rem;
          margin-left: -20%;
          justify-content: flex-start;
        }
      }
      &__list {
        margin-top: 3rem;
        margin-left: 12%;

        ul {
          list-style: none;
        }
        li {
          position: relative;

          &:before {
            content: "";
            width: 0.7rem;
            height: 0.7rem;
            border: 2px solid #f27c00;
            position: absolute;
            left: -8%;
            bottom: 50%;
            transform: rotate(45deg);

            @media ${props => props.theme.sizes.laptop} {
              left: -4%;
            }
          }
        }
      }
    }
  }
`
export const StyledAHAction = styled.section`
  padding: 3rem 0;
`

export const AHBigSign = styled.div`
  font-family: ${props => props.theme.fonts.titillium};
  color: #f3f3f3;
  width: calc(100%);
  font-size: 6rem;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: clip;
  z-index: -10;
  position: relative;

  @media ${props => props.theme.sizes.laptop} {
    font-size: 16rem;
    margin: 0 0 3rem 0;
  }

  @media ${props => props.theme.sizes.laptopL} {
    width: ${props => props.width || "calc(100% + 4rem)"};
    font-size: ${props => props.fontSize || "33rem"};
    margin: ${props => props.margin || "0 0 0 -4rem"};
  }

  &:after {
    position: absolute;
    display: ${props => props.display || "none"};
    content: "";
    width: 100%;
    height: 130%;
    background: rgba(255, 255, 255, 0.85);
    bottom: -100%;
    left: 0;

    @media ${props => props.theme.sizes.laptop} {
      width: 70%;
      left: 15%;
    }
  }
`

export const StyledAHActionItems = styled.div`
  font-family: ${props => props.theme.fonts.titillium};

  .actions__items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -15%;
    z-index: 100;
  }

  .actions__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.85);
    margin-bottom: 2rem;

    @media ${props => props.theme.sizes.laptop} {
      width: 50%;
      min-height: 30rem;
      margin-bottom: 0;
    }

    @media ${props => props.theme.sizes.laptopL} {
      width: 33.33%;
      min-height: 25rem;
      margin-bottom: 0;
    }

    .ai {
      &__number {
        font-size: 2rem;
        color: #a3a3a3;
        position: relative;
        max-width: 3rem;
        line-height: 1;

        @media ${props => props.theme.sizes.laptop} {
          margin: 1rem 0 2rem;
        }

        &:after {
          content: "";
          position: absolute;
          right: -0.2rem;
          width: 3px;
          height: 100%;
          top: 0;
          background-color: #a3a3a3;
        }
      }
      &__title {
        font-size: 2.15rem;
        color: #040405;
        margin-bottom: 2rem;
      }
      &__text {
        @media ${props => props.theme.sizes.laptop} {
          max-width: 70%;
        }
      }
    }

    &--1,
    &--2,
    &--3 {
      @media ${props => props.theme.sizes.laptopL} {
        padding-top: 15%;
        margin-bottom: 2rem;
      }
    }
  }
`

export const StyledAHLogoes = styled.section`
  font-family: ${props => props.theme.fonts.titillium};

  @media ${props => props.theme.sizes.laptop} {
    padding: 2rem 0;
  }

  .Container {
    padding-top: 2rem;

    @media ${props => props.theme.sizes.laptop} {
      padding-top: 5rem;
    }
  }
`
export const StyledAHLogoBanner = styled.div`
  font-family: ${props => props.theme.fonts.titillium};
  border: 0.6rem solid #f27c00;
  background: ${props => props.background};
  display: flex;
  flex-direction: column;
  padding: 5% 2%;
  margin-left: auto;
  position: relative;
  overflow: hidden;

  @media ${props => props.theme.sizes.laptop} {
    flex-direction: row;
    max-width: 90vw;
  }

  .left {
    padding: 2rem;
    @media ${props => props.theme.sizes.laptop} {
      width: 30%;
      padding: unset;
    }

    .name {
      font-size: 2rem;
      font-weight: 700;
      position: relative;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      color: ${props => props.textColor};

      &:after {
        content: "";
        width: 3rem;
        background: ${props => props.textColor};
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .desc {
      color: ${props => props.textColor};
      font-size: 1.125;

      @media ${props => props.theme.sizes.laptop} {
        max-width: 65%;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    @media ${props => props.theme.sizes.laptop} {
      width: 70%;
      padding: unset;
    }

    .logo {
      max-width: 20rem;
      z-index: 100;
    }

    .number {
      color: ${props => props.numberColor};
      font-size: 33rem;
      position: absolute;
      bottom: -70%;
      z-index: 1;
    }
  }
`
export const StyledAHcolors = styled.section`
  font-family: ${props => props.theme.fonts.titillium};
  padding: 1rem 0;

  @media ${props => props.theme.sizes.laptop} {
    padding: 3rem 0;
  }

  .AHImageWrapper {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.85);
    margin-bottom: 4rem;

    @media ${props => props.theme.sizes.laptop} {
      margin-bottom: 7rem;
    }
  }
  .AHfullWithImage {
    margin: 3rem -5rem;
    overflow-x: hidden;

    @media ${props => props.theme.sizes.laptop} {
      margin: 7rem 0 0;
    }
  }
`
export const StyledAHteaser = styled.section`
  font-family: ${props => props.theme.fonts.titillium};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;

  @media ${props => props.theme.sizes.laptop} {
    flex-direction: row;
  }

  .backgroundSign {
    position: absolute;
    left: -1%;
    top: 7%;
    font-size: 5rem;
    line-height: 0.9;
    z-index: 0;
    color: #f3891a;
    display: none;

    @media ${props => props.theme.sizes.laptop} {
      display: block;
    }
    @media ${props => props.theme.sizes.laptopL} {
      font-size: 10rem;
    }
  }

  .leftColumn {
    background: #f27c00;
    padding: 5% 10%;
    z-index: 10;

    @media ${props => props.theme.sizes.laptop} {
      width: 59%;
      padding: 10% 0 10% 10%;
      min-height: 70rem;
    }

    .left {
      z-index: 10;
    }
  }
  .rightColumn {
    @media ${props => props.theme.sizes.laptop} {
      width: 41%;
      padding-top: 10%;
    }

    .gatsby-image-wrapper {
      max-width: 40rem;
      z-index: 100;

      @media ${props => props.theme.sizes.laptop} {
        margin-left: -13rem;
      }
    }
  }
  .imageFull {
    max-width: 81rem;
    z-index: 100;

    @media ${props => props.theme.sizes.laptop} {
      margin-bottom: 14rem;
      margin: -13rem auto 0;
    }
  }
  .teaserImageBanner {
    position: relative;
    margin-bottom: 12rem;
    display: none;

    @media ${props => props.theme.sizes.laptop} {
      display: block;
      margin: 4rem 0 0;
    }
  }
  .teaserBanner {
    position: absolute;
    top: 50%;
    background: #fff;
    padding: 3rem 0 3rem 11.5rem;

    @media ${props => props.theme.sizes.desktop} {
      padding: 3rem 0 3rem 9rem;
    }
  }
  .teaserScreenImage {
    position: absolute;
    bottom: 0rem;
    width: 40%;
    right: 5rem;

    @media ${props => props.theme.sizes.laptopL} {
      right: 10rem;
      bottom: -10rem;
      width: unset;
    }

    .gatsby-image-wrapper {
      max-width: 44rem;
    }
  }
  .left {
    margin: 2rem 0;
  }
  .socialMediaWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media ${props => props.theme.sizes.laptop} {
      padding: 7rem 9rem;
    }

    .immageWrapper {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;

      @media ${props => props.theme.sizes.laptop} {
        width: 33.3%;
      }
    }
  }
  .imageAlign {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    @media ${props => props.theme.sizes.laptop} {
      margin: unset;
    }

    .gatsby-image-wrapper {
      margin: 0 auto;
    }
  }
`

export const StyledAHstart = styled.section`
  font-family: ${props => props.theme.fonts.titillium};
  padding: 3rem 0;

  @media ${props => props.theme.sizes.laptop} {
    padding: 7rem 0;
  }

  .rightCoulmn {
    flex: 0 0 50%;
    display: flex;

    @media ${props => props.theme.sizes.desktop} {
      justify-content: flex-start;
    }

    .gatsby-image-wrapper {
      display: none;

      @media ${props => props.theme.sizes.desktop} {
        width: 44rem;
        display: block;
      }
    }
  }

  .imageFull {
    margin-top: -10rem;
  }
  .ReapeterImages {
    display: flex;
    flex-direction: column;
    z-index: 10;
    padding: 2rem 0;

    @media ${props => props.theme.sizes.laptop} {
      flex-direction: row;
    }

    @media ${props => props.theme.sizes.desktop} {
      position: absolute;
      bottom: 12%;
      padding: 2rem;
    }

    .immageWrapper {
      @media ${props => props.theme.sizes.desktop} {
        margin-right: 1rem;
      }
    }

    .gatsby-image-wrapper {
      border: 0.5rem solid #f37c00;
      box-shadow: 2px 2px 10px black;
      display: block !important;

      @media ${props => props.theme.sizes.desktop} {
        width: 23rem;
      }
    }
  }
  .leftColumn {
    flex: 0 0 50%;
    .gatsby-image-wrapper {
      display: none;

      @media ${props => props.theme.sizes.desktop} {
        display: block;
      }
    }
  }
  .phoneImage {
    @media ${props => props.theme.sizes.desktop} {
      margin-left: -10%;
      margin-top: -17%;
    }

    .gatsby-image-wrapper {
      max-width: 30rem;
      display: none;

      @media ${props => props.theme.sizes.desktop} {
        display: block;
      }
    }
  }
  .tabletImage {
    .gatsby-image-wrapper {
      @media ${props => props.theme.sizes.desktop} {
        width: 70rem;
      }
    }
  }
`
export const StyledAHcampain = styled.section`
  font-family: ${props => props.theme.fonts.titillium};
  margin: 3rem 0;
  position: relative;

  @media ${props => props.theme.sizes.laptop} {
    margin: 7rem 0;
  }

  .fullWidthImageBanner {
    position: relative;
    height: 70vh;

    &__image {
      height: 100%;
    }

    .gatsby-image-wrapper {
      height: 100%;
    }

    @media ${props => props.theme.sizes.laptop} {
      height: unset;
    }

    &__content {
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      z-index: 100;

      @media ${props => props.theme.sizes.desktop} {
        height: 100%;
      }
    }
    &__image {
      position: relative;

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.5;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
      }
    }

    &__title {
      color: #fff;
      font-size: 2.1rem;
      text-align: center;
      line-height: 1;
      margin: 2rem 0;

      @media ${props => props.theme.sizes.desktop} {
        font-size: 8.1rem;
        margin: 7rem 0;
        color: #fff;
      }
    }
  }

  .column {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .price {
    font-size: 12rem;
    font-weight: 600;
    border: 4px solid #fff;
    color: ${props => props.priceColor || "#eee"};
  }

  .CampainTwoColumns {
    position: relative;
    flex-direction: column;

    @media ${props => props.theme.sizes.desktop} {
      flex-direction: row;
    }

    .bannerImage2 {
      width: 100%;
      z-index: 100;
      margin: 0 auto;

      @media ${props => props.theme.sizes.desktop} {
        position: absolute;
        width: 1100px;
        left: calc(0% + 170px);
        bottom: 30%;
      }
    }
  }

  .leftColumn {
    width: 100%;

    @media ${props => props.theme.sizes.desktop} {
      width: 50%;
    }
    .priceBox {
      position: relative;
      &:after {
        content: "";
        width: 80vw;
        height: 0.7rem;
        position: absolute;
        bottom: 50%;
        background: #f27c00;

        @media ${props => props.theme.sizes.desktop} {
          width: 50vw;
        }
      }
    }
  }
  .rightColumn {
    overflow: hidden;
    width: 100%;

    @media ${props => props.theme.sizes.desktop} {
      width: 50%;
    }
    .priceBox {
      position: relative;
      .icon {
        position: absolute;
        top: 48%;
        left: -49%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 60px;
        cursor: pointer;
        display: none;

        @media ${props => props.theme.sizes.desktop} {
          display: block;
        }
      }

      .arrow {
        position: absolute;
        top: 25px;
        width: 90%;
        height: 10px;
        background-color: #fff;
        animation: arrow 700ms linear infinite;
      }

      .arrow::after,
      .arrow::before {
        content: "";
        position: absolute;
        width: 60%;
        height: 10px;
        right: -8px;
        background-color: #fff;
      }

      .arrow::after {
        top: -12px;
        transform: rotate(45deg);
      }

      .arrow::before {
        top: 12px;
        transform: rotate(-45deg);
      }
    }
  }
  .laptopImage {
    @media ${props => props.theme.sizes.desktop} {
      margin-top: -20%;
    }
  }
`

export const StyledPrice = styled.div`
  font-family: ${props => props.theme.fonts.titillium};
  font-size: 5rem;
  font-weight: 600;
  border: 0.7rem solid ${props => props.priceColor || "#eee"};
  color: ${props => props.priceColor || "#eee"};
  padding: 1rem 3rem;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${props => props.theme.sizes.laptop} {
    margin-top: 10rem;
    font-size: 12rem;
    padding: 0 3rem;
    max-width: 50%;
  }
`
export const StyledVideosSection = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 7rem 0;
  flex-wrap: wrap;
  .StyledVideosSection__image {
    flex: 0 0 30%;
    min-width: 250px;
    margin: 1rem;
    img {
      width: 100%;
    }
    &:hover {
      cursor: pointer;
    }
  }
`
