import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"
import Container from "../../container.js"

import { StyledAHbanner, StyledTextSection } from "./StyleCsAutohop"

const AHbanner = props => {
  const data = useStaticQuery(graphql`
    query AhBanner {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            autohopDesciption
            autohopLogo {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  return (
    <div>
      <Container>
        <StyledAHbanner>
          <GatsbyImage
            image={getImage(ACF.autohopLogo.localFile)}
            alt={ACF.autohopLogo.altText}
            title={ACF.autohopLogo.title}
          />
          <h1>Case_study</h1>
        </StyledAHbanner>
        <StyledTextSection>
          <div className="left">
            <div className="name">AutoHOP</div>
            <div className="desc">Pionierski środek komunikacji miejskiej</div>
          </div>
          <div className="right">{ACF.autohopDesciption}</div>
        </StyledTextSection>
      </Container>
    </div>
  )
}
export default AHbanner
