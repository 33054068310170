import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../container.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledAHcolors, StyledTextSection, AHBigSign } from "./StyleCsAutohop"
import AHlogoBanner from "./AHlogoBanner"

const AHcolors = props => {
  const data = useStaticQuery(graphql`
    query AHcolors {
      allWpCaseStudie(filter: { slug: { eq: "autohop" } }) {
        nodes {
          ACF_autohop {
            claimText
            kolorystykaBanner {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
            claimImage {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                    placeholder: BLURRED
                  )
                  fixed(width: 2200) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const ACF = data.allWpCaseStudie.nodes[0].ACF_autohop

  return (
    <StyledAHcolors>
      <AHBigSign fontSize="26rem" display="flex">
        Kolorystyka
      </AHBigSign>
      <Container>
        <div className="AHImageWrapper">
          <GatsbyImage
            image={getImage(ACF.kolorystykaBanner.localFile)}
            alt={ACF.kolorystykaBanner.altText}
            title={ACF.kolorystykaBanner.title}
          />
        </div>
        <StyledTextSection>
          <div className="left">
            <div className="name">claim_marki</div>
            <div className="desc">{ACF.claimText}</div>
          </div>
          <div className="right">
            <div className="slogan">Hop i jedziesz</div>
          </div>
        </StyledTextSection>
      </Container>
      <div className="AHfullWithImage">
        <GatsbyImage image={getImage(ACF.claimImage.localFile)} alt={ACF.claimImage.altText || 'autohop'}  title={ACF.claimImage.title  || 'autohop'} />
      </div>
    </StyledAHcolors>
  )
}
export default AHcolors
